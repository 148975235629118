<script>
import AppLayout from "../../../layouts/AppLayout";
import PurchaseRentSearch from "@/views/purchase/_modules/PurchaseRentSearch.vue";
import PurchaseRentCountry from "@/views/purchase/_modules/PurchaseRentCountry";
import PurchaseRentPlan from "@/views/purchase/_modules/PurchaseRentPlan.vue";
import PurchaseRentShipment from "@/views/purchase/_modules/PurchaseRentShipment.vue";
import PurchaseRentDiscount from "@/views/purchase/_modules/PurchaseRentDiscount.vue";
import PurchaseCard from "@/views/purchase/_modules/PurchaseCard.vue";

import PurchaseRentPeriod from "@/views/purchase/rent/PurchaseRentPeriod";
import PurchaseRentPickup from "@/views/purchase/rent/PurchaseRentPickup";
import {mapGetters} from "vuex";
import PurchaseRentCoupon from "@/views/purchase/rent/PurchaseRentCoupon";
import PurchaseRentPackages from "./PurchaseRentPackages";
import PurchaseCheckoutProductInfo from "../checkout/PurchaseCheckoutProductInfo";
import PurchaseRentAddProduct from "./PurchaseRentAddProduct";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import PurchaseRentGiftCard from "@/views/purchase/rent/PurchaseRentGiftCard";
import {setOrderData} from "@/views/purchase/_store/actions";
import moment from "moment/moment";

export default {
    name: "Index.vue",

    components: {
        PurchaseCard,
        PurchaseRentDiscount,
        PurchaseRentShipment,
        PurchaseRentPlan,
        PurchaseRentSearch,
        PurchaseRentGiftCard,
        PurchaseRentAddProduct,
        PurchaseCheckoutProductInfo,
        PurchaseRentPackages,
        PurchaseRentCoupon, PurchaseRentPickup, PurchaseRentCountry, PurchaseRentPeriod, AppLayout,
    },

    data() {
        return {
            loading: false,
            validationMessages: '',
        }
    },

    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },

    computed: {
        ...mapGetters({
            user: 'getUser',
            shipmentTypes: 'getShipmentTypes',

            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
    },

    created() {
        let orderData = localStorage.getItem("orderData");
        if (orderData) {
            orderData = JSON.parse(orderData);
            this.$store.dispatch("purchase/setOrderData", orderData);
            this.$store.dispatch("purchase/setActiveOrderDataIndex", orderData.length - 1);
        }

        if (!orderData && !this.orderData.length) {
            this.$store.dispatch("purchase/addAnotherOrderData");
        }
    },

    methods: {
        selectCountry(country) {
            let plan = country.packages.length ? country.packages[0] : {};
            this.$store.dispatch('purchase/selectCountry', country);
            this.$store.dispatch('purchase/setCountryPackages', country.packages);
            this.$store.dispatch('purchase/setStartDate', moment().format());
            this.$store.dispatch('purchase/selectPlan', plan);
        },

        purchase() {
            this.validateAndSetMessage();
            if (!this.validationMessages) {
                this.$store.dispatch('purchase/setOrderData', this.orderData);
                this.$router.push({name: 'PurchaseCheckoutIndex'});
            }
        },

        addAnotherOrder() {
            this.validateAndSetMessage();

            if (!this.validationMessages) {
                this.$store.dispatch('purchase/addAnotherOrderData');
            }
        },

        validateAndSetMessage() {
            let message = "";
            let activeOrder = this.orderData[this.activeOrderDataIndex];

            if (!activeOrder.country_id) {
                message += "Please select your destination<br>";
            }
            if (!activeOrder.start_date) {
                message += "Please select your start date<br>";
            }
            if (!activeOrder.plan_id) {
                message += "Please select which plan do you want<br>";
            }
            if (activeOrder.shipment_type === 'pickup') {
                if (!activeOrder.pickup_location_id) {
                    message += "Please select pickup location<br>";
                }
                if (!activeOrder.pickup_date) {
                    message += "Please select pickup date<br>";
                }
            }
            if (activeOrder.shipment_type === 'delivery') {
                if (!activeOrder.delivery_type_id) {
                    message += "Please select delivery type<br>";
                }
            }

            if (message) {
                this.validationMessages = message;
            } else {
                this.validationMessages = '';
            }
        }
    }
}
</script>

<template>
    <app-layout>
        <div class="purchase-rent-index">
            <div class="container mx-auto">
                <purchase-rent-search
                    @select-country="selectCountry"
                />

                <purchase-rent-country
                    @select-country="selectCountry"
                />

                <div class="purchase-rent-index__body">
                    <div class="purchase-rent-index__body--left">
                        <purchase-rent-plan/>
                        <purchase-rent-shipment />
                        <purchase-rent-discount />
                    </div>
                    <div class="purchase-rent-index__body--right">
                        <div class="help-text">
                            Need a custom plan specifically for your trip? <br>
                            Contact us using the <span>Help</span> button at the bottom right of the page.
                        </div>

                        <div class="flex justify-end">
                            <div class="purchase-rent-index__card">
                                <purchase-card />

                                <div
                                    v-if="validationMessages"
                                    class="purchase-rent-index__card-validate-message"
                                    v-html="validationMessages"
                                ></div>

                                <div class="purchase-rent-index__card-buttons">
                                    <div class="purchase-rent-index__add-another-button">
                                        <button
                                            @click="addAnotherOrder"
                                        >Add another</button>
                                    </div>

                                    <div class="purchase-rent-index__purchase-button">
                                        <button
                                            @click="purchase"
                                        >Purchase</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<style lang="scss">
.purchase-rent-index {
    &__body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &--left {
            width: 100%;
            max-width: 50%;
        }

        &--right {
            width: 100%;
            max-width: 35%;

            .help-text {
                font-weight: 500;
                font-size: 1rem;
                text-align: right;
                margin-top: 6rem;
                margin-bottom: 2rem;
                width: 100%;

                span {
                    color: #7DD3FC;
                }
            }
        }
    }

    &__card {
        .purchase-card {
            margin-bottom: 1.5rem;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
        }

        &-validate-message {
            border: 2px solid #7DD3FC;
            border-radius: 8px;
            background: #ffffff;
            padding: 1rem 1.5rem;
            margin-bottom: 1.5rem;
            color: #CA0000;
        }
    }

    &__purchase-button {
        display: flex;
        justify-content: end;

        button {
            font-size: 24px;
            color: #ffffff;
            border-radius: 8px;
            width: 200px;
            height: 60px;
            background: #84CC16;
        }
    }

    &__add-another-button {
        margin-right: 1.5rem;

        button {
            font-size: 24px;
            color: #7DD3FC;
            border-radius: 8px;
            width: 200px;
            height: 60px;
            background: #ffffff;
            border: 2px solid #7DD3FC;

            &:hover {
                background: #7DD3FC;
                color: #ffffff;
            }
        }
    }
}

.calendar {
    position: absolute !important;
    z-index: 10;
    top: 50px;
    left: 25px;
}


@media (max-width: 1100px) {
    .purchase-rent-index {

        &__body {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &--left {
                width: 100%;
                max-width: 100%;
            }

            &--right {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
</style>