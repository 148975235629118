<script>
import TextInput from "@/components/ui/inputs/TextInput.vue";
import SwitchInput from "@/components/ui/inputs/SwitchInput.vue";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
    name: "PurchaseRentDiscount",
    components: {SwitchInput, TextInput},
    data() {
        return {
            couponCode: '',
            giftCardCode: '',
        }
    },
    computed: {
        ...mapGetters({
            giftCardDiscountAmount: 'purchase/getGiftCardDiscount',
            couponDiscountAmount: 'purchase/getCouponDiscountAmount',
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
        activeOrder() {
            return this.orderData[this.activeOrderDataIndex];
        }
    },
    watch: {
        activeOrder(order) {
            this.couponCode = order.coupon_code;
            this.giftCardCode = order.gift_card_code;
        }
    },
    created() {
        this.couponCode = this.activeOrder.coupon_code;
        this.giftCardCode = this.activeOrder.gift_card_code;
    },
    methods: {
        handlePromoCodeInput: _.debounce(function (e) {
            if (e && e.target.value) {
                this.$axios.get(`/coupons/${e.target.value}/getByCode`)
                    .then(res => {
                        this.$store.dispatch('purchase/setCoupon', res.data);
                    })
                    .catch(() => {
                        this.$store.dispatch('purchase/setCoupon', {});
                    });
            }
        }, 300),
        handleGiftCardCodeInput: _.debounce(function (e) {
            if (e && e.target.value) {
                this.$axios.get(`/gift-cards/${e.target.value}/getByCode`)
                    .then(res => {
                        this.$store.dispatch('purchase/setGiftCard', res.data);
                    })
                    .catch(() => {
                        this.$store.dispatch('purchase/setGiftCard', {});
                    });
            }
        }, 300),
        handleInsuranceStatus(value) {
            this.$store.dispatch('purchase/setInsuranceStatus', value);
        }
    }
}
</script>

<template>
    <div class="purchase-rent-discount">
        <div class="purchase-rent-discount__title">
            <h1>Discount and Insurance</h1>
        </div>

        <div class="purchase-rent-discount__body">
            <div class="purchase-rent-discount__promo-code">
                <text-input
                    label="Promo code"
                    placeholder="Promo code"
                    v-model="couponCode"
                    @input="handlePromoCodeInput"
                />

                <div
                    v-if="activeOrder.coupon_id"
                    class="purchase-rent-discount__promo-code__info"
                >
                    <h5>Your discount:</h5>

                    <span>
                        ${{ couponDiscountAmount }}
                    </span>
                </div>
            </div>
            <div class="purchase-rent-discount__gift-code">
                <text-input
                    label="Gift card"
                    placeholder="Gift card code"
                    v-model="giftCardCode"
                    @input="handleGiftCardCodeInput"
                />

                <div
                    v-if="activeOrder.gift_card_id"
                    class="purchase-rent-discount__promo-code__info"
                >
                    <h5>Your discount:</h5>

                    <span>
                        ${{ giftCardDiscountAmount }}
                    </span>
                </div>
            </div>
            <div class="purchase-rent-discount__device-protection">
                <switch-input
                    v-model="activeOrder.insurance_status"
                    label="Device Protection"
                    placeholder="Insurance: $2 Per day"
                    :default-value="activeOrder.insurance_status"
                    @toggle="handleInsuranceStatus"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .purchase-rent-discount {
        &__title {
            margin-bottom: 2rem;

            h1 {
                text-align: left;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: .5rem;
            }
        }

        &__body {
            border-radius: 8px;
            border: 2px solid #7DD3FC;
            padding: 1.5rem;
            width: 100%;
            background: #ffffff;
        }

        &__promo-code {
            margin-bottom: 1.5rem;

            .text-input {
                margin-bottom: 1rem;

                input {
                    text-transform: uppercase;
                }
            }

            &__info {
                display: flex;
                align-items: center;

                h5 {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    color: #7DD3FC;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }
        }

        &__gift-code {
            margin-bottom: 1.5rem;

            .text-input {
                margin-bottom: 1rem;

                input {
                    text-transform: uppercase;
                }
            }

            &__info {
                display: flex;
                align-items: center;

                h5 {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    color: #7DD3FC;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }
        }
    }
</style>