import moment from "moment/moment";

export const setSelectedCountry = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['continent_id'] = payload.continent.id;
    orderData[state.activeOrderDataIndex]['country_id'] = payload.id;
    orderData[state.activeOrderDataIndex]['country_name'] = payload.name;
    orderData[state.activeOrderDataIndex]['country_api_id'] = payload.api_country_id;

    state.orderData = orderData;
};

export const setPlanType = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['plan_type'] = payload;

    state.orderData = orderData;
};

export const setStartDate = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['start_date'] = payload;

    if (payload) {
        let numToAdd = 5;
        let endDate = moment(payload).add(orderData[state.activeOrderDataIndex]['number_of_days'], 'days');

        if (endDate) {
            endDate.add(numToAdd, 'days');

            if (endDate.day() === 0) {
                endDate.add(1, 'days');
            } else if (endDate.day() === 6) {
                endDate.add(2, 'days');
            }
        }

        orderData[state.activeOrderDataIndex]['return_date'] = endDate.format('MM/DD/YYYY');
    }

    state.orderData = orderData;
};

export const setNumberOfDays = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['number_of_days'] = payload;

    state.orderData = orderData;
};

export const setNumberOfDevices = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['number_of_devices'] = payload;

    state.orderData = orderData;
};

export const setSelectedPlan = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);

    orderData[state.activeOrderDataIndex]['plan_id'] = payload.id;
    orderData[state.activeOrderDataIndex]['plan_name'] = payload.name;
    orderData[state.activeOrderDataIndex]['plan_amount'] = payload.price;

    state.orderData = orderData;
};

export const setShipmentType = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);
    orderData[state.activeOrderDataIndex]['shipment_type'] = payload;
    state.orderData = orderData;
};

export const setPickupLocation = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['pickup_location_id'] = payload.id;
    state.orderData[state.activeOrderDataIndex]['pickup_location_api_id'] = payload.api_pickup_location_id;
    state.orderData[state.activeOrderDataIndex]['pickup_location_name'] = payload.name;
    state.orderData[state.activeOrderDataIndex]['pickup_location_note'] = payload.time_note;
};

export const setPickupDate = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['pickup_date'] = payload;
};

export const setDeliveryType = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);
    orderData[state.activeOrderDataIndex]['delivery_type_id'] = payload.id;
    orderData[state.activeOrderDataIndex]['delivery_type_api_id'] = payload.api_shipping_type_id;
    orderData[state.activeOrderDataIndex]['delivery_type_charges'] = payload.charges;
    orderData[state.activeOrderDataIndex]['delivery_type_name'] = payload.name;
    orderData[state.activeOrderDataIndex]['delivery_type_description'] = payload.description;
    state.orderData = orderData;
};

export const setDeliveryForm = (state, payload) => {
    let orderData = _.cloneDeep(state.orderData);
    orderData[state.activeOrderDataIndex]['address'] = payload.address;
    orderData[state.activeOrderDataIndex]['city'] = payload.city;
    orderData[state.activeOrderDataIndex]['country'] = payload.country;
    orderData[state.activeOrderDataIndex]['state'] = payload.state;
    orderData[state.activeOrderDataIndex]['zip_code'] = payload.zip_code;
    state.orderData = orderData;
};

export const setCoupon = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['coupon_id'] = payload.id;
    state.orderData[state.activeOrderDataIndex]['coupon_code'] = payload.coupon_name;
    state.orderData[state.activeOrderDataIndex]['coupon_type'] = payload.discount_type;
    state.orderData[state.activeOrderDataIndex]['coupon_discount_amount'] = payload.discount_amount;
};

export const setGiftCard = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['gift_card_id'] = payload.id;
    state.orderData[state.activeOrderDataIndex]['gift_card_code'] = payload.code;
    state.orderData[state.activeOrderDataIndex]['gift_discount_amount'] = payload.amount;
};

export const setInsuranceStatus = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['insurance_status'] = payload;
};

export const setReturnDate = (state, payload) => {
    state.orderData[state.activeOrderDataIndex]['return_date'] = payload;
};

export const setCountryPackages = (state, payload) => {
    state.countryPackages = payload;
};

export const setActiveOrderDataIndex = (state, payload) => {
    state.activeOrderDataIndex = payload;
};

export const setOrderData = (state, payload) => {
    console.log('setOrderData')
    state.orderData = payload;
    localStorage.setItem("orderData", JSON.stringify(state.orderData));
};

export const clearOrderData = (state) => {
    state.orderData = [];
    localStorage.removeItem("orderData");
};