<script>
export default {
    name: "SwitchInput",
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        defaultValue: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            default() {
                return null;
            },
        },
    },
    data() {
        return {
            value: false,
            isFocused: false
        }
    },
    watch: {
        modelValue(value) {
            this.value = value;
        }
    },
    created() {
        this.value = this.defaultValue;
    },
    methods: {
        handleToggle() {
            this.value = !this.value;
            this.$emit("toggle", this.value);
            this.$emit('update:modelValue', this.value)
        }
    }
}
</script>

<template>
    <div class="switch-input">
        <label
            v-if="label"
            class="switch-input__label"
        >
            {{ label }}
        </label>

        <div
            class="switch-input__form"
            @click="handleToggle"
        >
            <div
                class="switch-input__switcher"
                :class="{'active': value}"
            >
                <div class="switch-input__switcher--toggle"></div>
            </div>
            <span
                v-if="placeholder"
                class="switch-input__placeholder"
            >
                {{ placeholder }}
            </span>
        </div>
    </div>
</template>

<style lang="scss">
    .switch-input {
        position: relative;

        &__label {
            font-size: 14px;
            color: #000000;
            margin-bottom: .5rem;
            display: inline-block;
        }

        &__placeholder {
            font-size: 14px;
            color: #000000;
            cursor: pointer;
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__switcher {
            width: 60px;
            height: 30px;
            border-radius: 8px;
            border: 2px solid #7DD3FC;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 .1rem;
            cursor: pointer;
            transition: .2s;

            &--toggle {
                border: 2px solid #7DD3FC;
                width: 30px;
                height: 24px;
                border-radius: 5px;
                background: #ffffff;
                transition: .2s;
            }
        }

        &__switcher.active {
            justify-content: flex-end;

            .switch-input__switcher--toggle {
                background: #7DD3FC;
            }
        }
    }
</style>