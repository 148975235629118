<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {getActiveOrderDataIndex, getOrderTotalAmount} from "@/views/purchase/_store/getters";
import {removeOrderDataByIndex, setReturnDate} from "@/views/purchase/_store/actions";
import _ from "lodash";

export default {
    name: "PurchaseCard",

    computed: {
        moment() {
            return moment
        },
        ...mapGetters({
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
            orderSubtotalAmount: 'purchase/getOrderSubtotalAmount',

            selectedCountry: 'purchase/getSelectedCountry',
            startDate: 'purchase/getStartDate',
            numberOfDays: 'purchase/getNumberOfDays',
            numberOfDevices: 'purchase/getNumberOfDevices',
            planType: 'purchase/getPlanType',
            selectedPlan: 'purchase/getSelectedPlan',
            shipmentType: 'purchase/getShipmentType',
            deliveryPrice: 'purchase/getDeliveryPrice',
            couponDiscountAmount: 'purchase/getCouponDiscountAmount',
            giftCardDiscountAmount: 'purchase/getGiftCardDiscount',
            insuranceAmount: 'purchase/getInsuranceAmount',
            orderTotalAmount: 'purchase/getOrderTotalAmount',
        }),
    },

    methods: {
        isCardEmpty(i) {
            if (!!this.orderData[i].plan_id) return false;

            return true;
        },

        editOrder(i) {
            this.$store.dispatch('purchase/setActiveOrderDataIndex', i);

            let orders = _.cloneDeep(this.orderData);
            let emptyOrderIndex = orders.findIndex((order) => !order.plan_id);

            if (emptyOrderIndex > -1) {
                orders.splice(emptyOrderIndex, 1);
            }

            this.$store.dispatch('purchase/setOrderData', orders);
        },

        removeOrder(i) {
            this.$store.dispatch('purchase/removeOrderDataByIndex', i);
        },

        returnDate(i) {
            let numToAdd = 5;
            let orderData = _.cloneDeep(this.orderData[i]);
            if(orderData && orderData.start_date) {
                let endDate = moment(orderData.start_date).add(orderData.number_of_days, 'days');

                if (endDate) {
                    endDate.add(numToAdd, 'days');

                    if (endDate.day() === 0) {
                        endDate.add(1, 'days');
                    } else if (endDate.day() === 6) {
                        endDate.add(2, 'days');
                    }
                }

                endDate = endDate.format('MM/DD/YYYY');

                this.$store.dispatch('purchase/setReturnDate', endDate);

                return endDate;
            }
        },
    }
}
</script>

<template>
    <div class="purchase-card">
        <template v-for="(order, i) in orderData">
            <div
                class="purchase-card__item"
                :class="{'short': i !== activeOrderDataIndex}"
            >
                <template v-if="isCardEmpty(i)">
                    <h1 class="empty-text">
                        Your card is empty.
                    </h1>
                </template>
                <template v-if="!isCardEmpty(i)">
                    <div class="purchase-card__item--head">
                        <div class="left">
                            <h1>SkyGo Wifi Rental</h1>
                            <p>{{ order.country_name }}</p>
                            <p v-if="order.start_date">{{ order.number_of_days }} Days: {{ moment(order.start_date).format('MM/DD/YYYY') }} - {{ moment(order.start_date).add(order.number_of_days, 'days').format('MM/DD/YYYY') }}</p>
                            <p v-if="i !== activeOrderDataIndex">Total amount: ${{ order.total_amount }}</p>
                        </div>
                        <div class="right">
                            <img src="@/assets/png/pro-2.png" />
                            <button
                                class="purchase-card__item-edit-btn"
                                @click="editOrder(i)"
                            >
                                Edit
                            </button>
                            <button
                                class="purchase-card__item-remove-btn"
                                @click="removeOrder(i)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                    <div class="purchase-card__item--body">
                        <div class="info">
                            <span>Plan</span>
                            <span>{{ order.plan_name }}</span>
                        </div>
                        <div class="info">
                            <span>Device Quantity</span>
                            <span>{{ order.number_of_devices }}</span>
                        </div>
                        <div class="info">
                            <span>Subtotal</span>
                            <span>${{ orderSubtotalAmount }}</span>
                        </div>
                        <div class="info">
                            <span>Shipping method</span>
                            <span class="capitalize">{{ shipmentType }}</span>
                        </div>
                        <div class="info">
                            <span>Shipping price</span>
                            <span>${{ deliveryPrice }}</span>
                        </div>
                        <div class="info">
                            <span>Insurance</span>
                            <span>${{ insuranceAmount }}</span>
                        </div>
                        <div class="info">
                            <span>Promo code Discount</span>
                            <span>${{ couponDiscountAmount }}</span>
                        </div>
                        <div class="info">
                            <span>Gift card Discount</span>
                            <span>${{ giftCardDiscountAmount }}</span>
                        </div>
                    </div>
                    <div class="purchase-card__item--foot">
                        <div class="total">
                            <span>Total</span>
                            <span>${{ orderTotalAmount }}</span>
                        </div>
                        <div
                            v-if="order.plan_id"
                            class="alert"
                        >
                            Return by: {{ orderData[i].return_date }} Late fees begin accumulating after {{ orderData[i].return_date }}, $10 per day.
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    .purchase-card {
        &__item {
            width: 100%;
            padding: 1.5rem;
            border-radius: 8px;
            border: 2px solid #FE9634;
            background: #ffffff;
            margin-bottom: 1.5rem;

            .empty-text {
                font-weight: 400;
                font-style: italic;
            }

            &--head {
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid #7DD3FC;
                padding-bottom: 1rem;
                margin-bottom: 1rem;

                .left {
                    h1 {
                        font-size: 1rem;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: .5rem;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 400;
                        color: #000000;
                        margin-bottom: .5rem;
                    }
                }

                .right {
                    width: 120px;
                    height: 120px;
                }
            }

            &--body {
                border-bottom: 2px solid #7DD3FC;
                padding-bottom: 1rem;
                margin-bottom: 1rem;

                .info {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    span {
                        font-size: 1rem;
                        color: #000000;
                    }
                }
            }

            &--foot {
                .total {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    span {
                        font-size: 24px;
                        color: #7DD3FC;
                        font-weight: 500;
                    }
                }

                .alert {
                    font-size: 12px;
                    color: #CA0000;
                    font-weight: 400;
                    margin-bottom: 1rem;
                }
            }

            &-edit-btn {
                display: none;
                border-radius: 8px;
                border: 2px solid #7DD3FC;
                color: #7DD3FC;
                width: 100%;
                padding: .25rem .5rem;
                margin-bottom: 1rem;

                &:hover {
                    background: #7DD3FC;
                    color: #ffffff;
                }
            }

            &-remove-btn {
                display: none;
                border-radius: 8px;
                border: 2px solid #FE9634;
                color: #FE9634;
                width: 100%;
                padding: .25rem .5rem;

                &:hover {
                    background: #FE9634;
                    color: #ffffff;
                }
            }

            &.short {
                margin-bottom: 1.5rem;

                .purchase-card__item--head {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;

                    .right {
                        height: 100px;

                        img {
                            height: 95px;
                            display: none;
                        }

                        &-edit-btn {
                            display: block;
                        }

                        &-remove-btn {
                            display: block;
                        }
                    }
                }
                .purchase-card__item--body {
                    display: none;
                }
                .purchase-card__item--foot {
                    display: none;
                }
                .purchase-card__item-edit-btn {
                    display: block;
                }
                .purchase-card__item-remove-btn {
                    display: block;
                }
            }
        }
    }
</style>