<script>
import {mapGetters} from "vuex";

export default {
    name: "PurchaseRentSearch",

    data() {
        return {
            selectedDestination: '',
            destinations: [],
            isSearching: false,
        };
    },

    computed: {
        ...mapGetters({
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
    },

    created() {
        if (this.orderData.country_id) {
            this.selectedDestination = this.orderData[this.activeOrderDataIndex].country_name;
        }
    },

    watch: {
        orderData(value) {
            this.selectedDestination = value[this.activeOrderDataIndex].country_name;
        }
    },

    methods: {
        handleFocus: _.debounce(function (value) {
            this.isSearching = value;
        }, 100),

        handleInput: _.debounce(function (value) {
            this.searchDestination(value);
        }, 300),

        async searchDestination(query) {
            if (!query || query.length < 2) {
                this.destinations = [];
                return;
            }

            try {
                const response = await this.$axios.get(`/countries/search`, {
                    params: {
                        query: query,
                        limit: 10,
                    }
                });

                this.destinations = await response.data;
            } catch (error) {
                console.error("Error while fetching search results:", error);
            }
        },

        selectDestination(destination) {
            if (destination) {
                this.selectedDestination = destination.name;
                this.$emit('selectCountry', destination);
            }
        }
    }
}
</script>

<template>
    <div class="purchase-rent-search">
        <div class="purchase-rent-search__title">
            <h1>Where will your next adventure take you?</h1>
            <h5>Choose your destination and pair it with one of our Pocket WiFi devices and internet plans. Stay connected with ease, no matter where you go!</h5>
        </div>

        <div class="purchase-rent-search__form">
            <div class="purchase-rent-search__form--bar">
                <div class="purchase-rent-search__form--bar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#FF8818"><path d="M792-120.67 532.67-380q-30 25.33-69.64 39.67Q423.39-326 378.67-326q-108.44 0-183.56-75.17Q120-476.33 120-583.33t75.17-182.17q75.16-75.17 182.5-75.17 107.33 0 182.16 75.17 74.84 75.17 74.84 182.27 0 43.23-14 82.9-14 39.66-40.67 73l260 258.66-48 48Zm-414-272q79.17 0 134.58-55.83Q568-504.33 568-583.33q0-79-55.42-134.84Q457.17-774 378-774q-79.72 0-135.53 55.83-55.8 55.84-55.8 134.84t55.8 134.83q55.81 55.83 135.53 55.83Z"/></svg>
                </div>

                <div class="purchase-rent-search__form--bar-input">
                    <input
                        v-model="selectedDestination"
                        type="text"
                        placeholder="Search for your destination in over 200+ countries and regions"
                        aria-label="Search destinations"
                        @input="handleInput($event.target.value)"
                        @focus="handleFocus(true)"
                        @blur="handleFocus(false)"
                    >
                </div>
            </div>

            <div
                v-if="isSearching"
                class="purchase-rent-search__form--menu"
            >
                <div class="purchase-rent-search__form--menu-items">
                    <div
                        v-if="destinations.length"
                        v-for="destination in destinations"
                        class="purchase-rent-search__form--menu-item"
                        @click="selectDestination(destination)"
                    >
                        {{ destination.name }}
                    </div>

                    <h5
                        v-if="!destinations.length"
                    >
                        Searching...
                    </h5>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

.purchase-rent-search {
    position: relative;
    margin-top: 2rem;

    &__title {
        margin-bottom: 1.5rem;

        h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: .5rem;
        }

        h5 {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
        }
    }

    &__form {
        position: relative;
        &--bar {
            display: flex;
            height: 68px;
            width: 100%;
            border: 2px solid #FF8818;
            border-radius: .5rem;
            background: #ffffff;
        }

        &--bar-icon {
            width: 80px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--bar-input {
            width: 100%;
            padding: 0 .5rem 0 0;

            input {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                font-size: 20px;

                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }

        &--menu {
            width: 100%;
            min-height: 56px;
            background: #ffffff;
            border: 2px solid #FF8818;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            border-top: none;
            position: absolute;
            top: 60px;
            padding: .5rem 0;
            z-index: 100;
        }

        &--menu-items {
            h5 {
                margin: .5rem 1.5rem;
            }
        }

        &--menu-item {
            padding: .5rem 1.5rem;
            cursor: pointer;

            &:hover {
                background: #7DD3FC;
                color: #fff;
            }
        }
    }

    @media (max-width: 768px) {
        .purchase-rent-search__title h1 {
            font-size: 18px;
        }

        .purchase-rent-search__form--bar {
            height: 56px;
        }

        .purchase-rent-search__form--bar-input input {
            font-size: 16px;
        }

        .purchase-rent-search__form--menu {
            top: 48px;
        }
    }
}

</style>