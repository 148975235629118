<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: "PurchaseRentPlan",

    data() {
        return {
            startDateValue: null,
            startDateMasks: {
                input: 'MM/DD/YYYY',
            },
            startMinDate: new Date(Date.now()),
        }
    },

    computed: {
        ...mapGetters({
            // selectedCountry: 'purchase/getSelectedCountry',
            // startDate: 'purchase/getStartDate',
            // numberOfDays: 'purchase/getNumberOfDays',
            // numberOfDevices: 'purchase/getNumberOfDevices',
            // planType: 'purchase/getPlanType',
            // selectedPlan: 'purchase/getSelectedPlan',

            countryPackages: 'purchase/getCountryPackages',
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
        activeOrder() {
            return this.orderData[this.activeOrderDataIndex];
        },
        hasMonthTypePlan() {
            let findMonthPlan = this.countryPackages.find(plan => {
                return plan.type === 'month';
            });

            return !!findMonthPlan;
        }
    },

    created() {
        this.startDateValue = this.activeOrder.start_date || moment().format();
        this.$store.dispatch('purchase/setStartDate', this.startDateValue);
        this.loadCountryPackages();
    },

    methods: {
        changePlanType(type) {
            this.$store.dispatch("purchase/setPlanType", type);
        },
        selectStartDate(value) {
            this.$store.dispatch('purchase/setStartDate', moment(value.date).format());
        },
        handleNumberOfDays(type) {
            let numberOfDays = this.activeOrder.number_of_days;
            if (type === 'minus' && numberOfDays > 1) {
                this.$store.dispatch('purchase/setNumberOfDays', numberOfDays - 1);
            } else if (type === 'plus') {
                this.$store.dispatch('purchase/setNumberOfDays', numberOfDays + 1);
            }
        },
        handleNumberOfDevices(type) {
            let numberOfDevices = this.activeOrder.number_of_devices;
            if (type === 'minus' && numberOfDevices > 1) {
                this.$store.dispatch('purchase/setNumberOfDevices', numberOfDevices - 1);
            } else if (type === 'plus') {
                this.$store.dispatch('purchase/setNumberOfDevices', numberOfDevices + 1);
            }
        },
        handlePlan(plan) {
            this.$store.dispatch('purchase/selectPlan', plan);
        },
        async loadCountryPackages() {
            let orderData = this.activeOrder;
            if (orderData.country_id && !this.countryPackages.length) {
                try {
                    const response = await this.$axios.get(`/countries/${orderData.country_id}/get-country-packages`);
                    this.$store.dispatch('purchase/setCountryPackages', response.data);
                } catch (error) {
                    console.error("Error while fetching packages:", error);
                }
            }
        }
    }
}
</script>

<template>
    <div class="purchase-rent-plan">
        <div class="purchase-rent-plan__title">
            <h1>Select your Plan</h1>
        </div>

        <div class="purchase-rent-plan__types">
            <div class="purchase-rent-plan__switcher">
                <div
                    class="purchase-rent-plan__switcher-day"
                    :class="{'active': activeOrder.plan_type === 'day'}"
                    @click="changePlanType('day')"
                >
                    Day plans
                </div>
                <template
                    v-if="!hasMonthTypePlan"
                >
                    <div
                        class="purchase-rent-plan__switcher-month disabled"
                        :class="{'active': activeOrder.plan_type === 'month'}"
                        title="Destination does not have monthly plans"
                    >
                        Month plans
                    </div>
                </template>
                <template
                    v-if="hasMonthTypePlan"
                >
                    <div
                        class="purchase-rent-plan__switcher-month"
                        :class="{'active': activeOrder.plan_type === 'month'}"
                        @click="changePlanType('month')"
                    >
                        Month plans
                    </div>
                </template>
            </div>
        </div>

        <h5 class="purchase-rent-plan__description">
            Explore the {{ activeOrder.country_name || 'your destination' }} with ease using our Pocket WiFi devices and internet plans. Select the duration and daily
            high-speed data allowance that fits your travel needs. Stay connected effortlessly with our flexible plans.
        </h5>

        <div class="purchase-rent-plan__body">
            <h2>Choose Day(s)</h2>

            <div class="br"></div>

            <div class="purchase-rent-plan__body-date">
                <div class="purchase-rent-plan__body-date__start-date">
                    <h4>Select start date</h4>
                    <h5>Select the start date of your trip.</h5>

                    <v-date-picker
                        v-model="startDateValue"
                        :masks="startDateMasks"
                        :min-date="startMinDate"
                        @dayclick="selectStartDate"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="relative">
                                <input
                                    class="purchase-rent-plan__body-date__start-date__input"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    placeholder="Select start date"
                                />
                                <svg class="date-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/></svg>
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div class="purchase-rent-plan__body-date__number-of-days">
                    <h4>Number of days</h4>
                    <h5>More days, lower daily price.</h5>

                    <div class="purchase-rent-plan__body-date__number-of-days__input">
                        <div
                            class="minus"
                            @click="handleNumberOfDays('minus')"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M200-440v-80h560v80H200Z"/></svg>
                        </div>
                        <div class="number">
                            {{ activeOrder.number_of_days }}
                        </div>
                        <div
                            class="plus"
                            @click="handleNumberOfDays('plus')"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="br"></div>

            <h2>Choose Plan Type</h2>

            <div class="purchase-rent-plan__body-plan-types">
                <div
                    v-if="countryPackages.length"
                    v-for="plan in countryPackages"
                    class="purchase-rent-plan__body-plan-type"
                    :class="{'active': activeOrder.plan_id === plan.id}"
                    @click="handlePlan(plan)"
                >
                    <div class="flex justify-between">
                        <div class="purchase-rent-plan__body-plan-type-info">{{ plan.name }}</div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#7DD3FC"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                        </div>
                    </div>
                    <div class="purchase-rent-plan__body-plan-type-price">
                        ${{ plan.price }} Per day
                    </div>
                </div>
                <div
                    v-if="!countryPackages.length"
                    class="font-light"
                >
                    First select destination
                </div>
            </div>

            <div class="br"></div>

            <template v-if="activeOrder.plan_type === 'month'">
                <h2>Device type</h2>

                <div class="purchase-rent-plan__body-device-types">
                    <div class="purchase-rent-plan__body-device-type">
                        <img src="@/assets/png/devices/plan-device-type-1.png" alt="">
                        <h5>4G WiFi</h5>
                    </div>
                    <div class="purchase-rent-plan__body-device-type">
                        <img src="@/assets/png/devices/plan-device-type-2.png" alt="">
                        <h5>5G WiFi</h5>
                    </div>
                </div>

                <div class="br"></div>
            </template>

            <h2>Device quantity</h2>

            <div class="purchase-rent-plan__body-device-quantity">
                <h4>Number of devices</h4>

                <div class="purchase-rent-plan__body-date__number-of-days__input">
                    <div
                        class="minus"
                        @click="handleNumberOfDevices('minus')"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M200-440v-80h560v80H200Z"/></svg>
                    </div>
                    <div class="number">
                        {{ activeOrder.number_of_devices }}
                    </div>
                    <div
                        class="plus"
                        @click="handleNumberOfDevices('plus')"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.purchase-rent-plan {
    position: relative;
    margin-top: 2rem;

    &__title {
        margin-bottom: 2rem;

        h1 {
            text-align: left;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: .5rem;
        }
    }

    &__types {
        background: #fff;
        margin-bottom: 1.5rem;
    }

    &__switcher {
        border-radius: 8px;
        border: 2px solid #7DD3FC;
        padding: .25rem;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-day, &-month {
            border-radius: 8px;
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            height: 60px;
            width: 49.8%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #fff;

            &:hover {
                color: #7DD3FC;

                &.active {
                    color: #fff;
                }
            }

            &.active {
                background: #7DD3FC;
                color: #fff;
            }
        }
    }

    &__description {
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 1.5rem;
    }

    &__body {
        background: #ffffff;
        border: 2px solid #7DD3FC;
        border-radius: 8px;
        padding: 1.5rem 1rem;

        h2 {
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: 1.5rem;
        }

        .br {
            border: 1px solid #7DD3FC;
            margin-bottom: 1.5rem;
            width: 100%;
        }

        &-date {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            width: 100%;

            h4 {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: .5rem;
            }

            h5 {
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 1rem;
            }

            &__start-date {
                width: 48%;

                &__input {
                    width: 100%;
                    box-sizing: border-box;
                    background: #ffffff;
                    border: 2px solid #FE9634;
                    border-radius: 8px;
                    padding: .8rem 3rem;
                    font-size: 1rem;
                    outline: none;
                }

                .date-icon {
                    position: absolute;
                    top: .9rem;
                    left: 1rem;
                }
            }

            &__number-of-days {
                width: 48%;

                &__input {
                    background: #ffffff;
                    border: 2px solid #FE9634;
                    border-radius: 8px;
                    padding: 0 1rem;
                    height: 53px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .plus, .minus {
                        cursor: pointer;
                    }

                    .number {
                        font-weight: 500;
                        font-size: 26px;
                        color: #FE9634;
                        user-select: none;
                    }
                }
            }
        }

        &-plan-types {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.5rem;
        }

        &-plan-type {
            background: #ffffff;
            width: 147px;
            padding: 1rem;
            border-radius: 8px;
            border: 2px solid #7DD3FC;
            cursor: pointer;
            margin: 0 1rem 1rem 0;

            &:hover, &.active {
                color: #ffffff;
                background: #7DD3FC;

                svg {
                    fill: #ffffff;
                }
            }

            &-info {
                font-size: 18px;
                font-weight: 300;
                margin-bottom: .5rem;
            }

            &-price {
                font-size: 16px;
                font-weight: 400;
            }
        }

        &-device-types {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.5rem;
        }

        &-device-type {
            border-radius: 8px;
            border: 2px solid #7DD3FC;
            background: #ffffff;
            padding: 1rem;
            margin: 0 1rem 1rem 0;
            width: 160px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            cursor: pointer;

            img {
                height: 64px;
                margin-bottom: .5rem;
            }

            h5 {
                width: 100%;
                text-align: center;
                font-size: 1rem;
            }

            &:hover, &.active {
                color: #ffffff;
                background: #7DD3FC;
            }
        }

        &-device-quantity {
            width: 50%;

            h4 {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: .5rem;
            }
        }
    }
}
</style>