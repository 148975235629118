<template>
    <template v-if="!loading.component">
        <div class="w-full">
            <p class="text-2xl font-semibold mb-8 text-left text-sky-300">
                Card information
            </p>

            <div id="card-element" class="mb-8"></div>

            <div
                v-if="paymentData.stripe && paymentData.elements"
                class="flex justify-center md:justify-end"
            >
                <button
                    v-if="!loading.checkout"
                    class='w-full justify-center lg:w-1/2 pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-4 px-12 rounded-lg bg-lime-500'
                    @click="checkoutOrder"
                >
                    Checkout
                </button>

                <button
                    v-if="loading.checkout"
                    class="pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-5 px-20 rounded-lg bg-lime-500"
                    disabled
                >
                    Loading...
                </button>
            </div>
        </div>
    </template>
</template>

<script>
import {loadStripe, StripePaymentElementOptions} from '@stripe/stripe-js';
import moment from "moment";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "PurchaseCheckoutPayment",

    data() {
        return {
            stripe: null,
            elements: null,
            loading: {
                component: false,
                checkout: false,
            },
        }
    },

    computed: {
        ...mapGetters({
            paymentData: 'getPaymentData',
            couponDiscountAmount: 'getCouponDiscountAmount',
            insuranceAmount: 'getInsuranceAmount',
            orderData: 'purchase/getOrderData',
            allOrderData: 'getAllOrderData',
            deductedAmount: 'getDeductedAmount'
        }),
    },

    mounted() {
        this.initStripe();
    },

    methods: {
        async initStripe() {
            let paymentData = _.cloneDeep(this.paymentData);
            paymentData.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
            paymentData.elements = paymentData.stripe.elements();
            paymentData.cardElement = paymentData.elements.create('card')
            paymentData.cardElement.mount('#card-element');
            this.$store.dispatch('setPaymentData', paymentData);
        },

        submit() {
            this.loading.checkout = true;
            if (this.orderData.first_name
                && this.orderData.last_name
                && this.orderData.email
                && this.orderData.phone_number
            ) {
                this.paymentData.stripe.confirmPayment({
                    elements: this.paymentData.elements,
                    redirect: 'if_required',
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: this.orderData.first_name,
                                email: this.orderData.email
                            }
                        }
                    }
                })
                    .then(result => {
                        if (result.error) {
                            this.loading.checkout = false;
                        } else if (result) {
                            this.checkoutOrder();
                        }
                    })
                    .catch(e => {
                        this.loading.checkout = false;
                    });
            } else {
                this.$toast.warning('Please fill all fields', {
                    position: "bottom-left",
                });
                this.loading.checkout = false
            }
        },

        async checkoutOrder() {
            this.loading.checkout = true;
            let paymentIntent = null;
            let order = this.orderData[0];

            try {
                paymentIntent = await this.$axios.get(`users/${order.customer_id}/payment-intent`);
            } catch (e) {
                this.$toast.info(e.data.message);
                return;
            }

            const clientSecret = paymentIntent.data.client_secret;
            const {setupIntent, error} = await this.paymentData.stripe.confirmCardSetup(
                clientSecret, {
                    payment_method: {
                        card: this.paymentData.cardElement,
                    }
                }
            );
            if (error) {
                this.$toast.info(error.message);
                this.loading.checkout = false
                return;
            }

            let form = [];
            this.orderData.forEach((order, i) => {
                let test = {
                    order_id: order.order_id,
                    customer_id: order.customer_id,
                    payment_method_id: setupIntent.payment_method,
                    gift_card_id: order.gift_card_id,
                    gift_card_used_amount: this.deductedAmount,
                }
                form.push(test)
            })
            this.$axios.post(`orders/checkout`, form)
                .then(res => {
                    this.$router.push({name: 'PurchaseCheckoutResult'});
                    this.loading.checkout = false;
                }).catch(e => {
                    this.loading.checkout = false;
                    this.$toast.info(e.data.message);
                });
        }
    }
}
</script>

<style lang="scss">
#card-element {
    background: #ffffff;
    border-color: #7dd3fc;
    border-width: 2px;
    border-radius: 0.75rem;
    padding: .8rem 1rem;
}
</style>