export const getSelectedCountry = state => state.orderData[state.activeOrderDataIndex]['country'];

export const getPlanType = state => state.orderData[state.activeOrderDataIndex]['plan_type'];

export const getStartDate = state => state.orderData[state.activeOrderDataIndex]['start_date'];

export const getNumberOfDays = state => state.orderData[state.activeOrderDataIndex]['number_of_days'];

export const getNumberOfDevices = state => state.orderData[state.activeOrderDataIndex]['number_of_devices'];

export const getSelectedPlan = state => state.orderData[state.activeOrderDataIndex]['plan'];

export const getShipmentType = state => state.orderData[state.activeOrderDataIndex]['shipment_type'];

export const getDeliveryType = state => state.orderData[state.activeOrderDataIndex]['delivery_type'];

export const getCoupon = state => state.orderData[state.activeOrderDataIndex]['coupon'];

export const getGiftCard = state => state.orderData[state.activeOrderDataIndex]['gift_card'];

export const getInsuranceStatus = state => state.orderData[state.activeOrderDataIndex]['insurance_status'];
export const getUsaStates = state => state.usaStates;
export const getCountryPackages = state => state.countryPackages;

export const getActiveOrderDataIndex = state => state.activeOrderDataIndex;

export const getOrderData = state => state.orderData;

export const getCouponDiscountAmount = (state, getters) => {
    const orders = getters.getOrderData;
    const activeOrderIndex = getters.getActiveOrderDataIndex;
    const order = orders[activeOrderIndex];
    const price = getters.getOrderSubtotalAmount;
    const couponId = order.coupon_id;
    const couponType = order.coupon_type;
    const couponDiscountAmount = order.coupon_discount_amount;

    let discountAmount = 0;

    if (couponId) {
        if (state.couponTypes[1].tag === couponType) {
            discountAmount = couponDiscountAmount;
        } else if (state.couponTypes[2].tag === couponType) {
            discountAmount = Math.round(price * couponDiscountAmount) / 100;
        }
    }

    return discountAmount;
};

export const getGiftCardDiscount = (state, getters) => {
    const orders = getters.getOrderData;
    const activeOrderIndex = getters.getActiveOrderDataIndex;
    const order = orders[activeOrderIndex];

    let discountAmount = 0;

    if (order.gift_card_id) {
        discountAmount = order.gift_discount_amount;
    }

    return discountAmount;
};

export const getInsuranceAmount = (state, getters) => {
    const numberOfDevices = getters.getNumberOfDevices;
    const numberOfDays = getters.getNumberOfDays;
    let amount = 0;

    if (getters.getInsuranceStatus) {
        amount = numberOfDays * state.insurance_amount * numberOfDevices;
    }

    return amount;
};

export const getDeliveryPrice = (state) => {
    let price = 0;
    let orderData = state.orderData[state.activeOrderDataIndex];

    if (orderData.shipment_type === 'delivery' && orderData.delivery_type_id) {
        price += orderData.delivery_type_charges;
    }

    return price;
};

export const getOrderSubtotalAmount = (state, getters) => {
    const orders = getters.getOrderData;
    const activeOrderIndex = getters.getActiveOrderDataIndex;
    const order = orders[activeOrderIndex];
    const numberOfDevices = order.number_of_devices;
    const numberOfDays = order.number_of_days;
    const country_api_id = order.country_api_id;
    const plan_id = order.plan_id;
    const plan_amount = order.plan_amount;

    let amount = 0;

    if (!plan_id) {
        return amount;
    }

    switch (true) {
        case country_api_id === 198 || country_api_id === 180:
            amount += numberOfDays <= 10 ? plan_amount : 7;
            break;
        default:
            amount += numberOfDays <= 10 ? plan_amount : plan_amount - 3;
    }

    amount = amount * numberOfDays * numberOfDevices;
    return Math.round(amount * 100) / 100;
};

export const getOrderTotalAmount = (state, getters) => {
    let totalAmount = 0;

    const giftCardDiscount = getters.getGiftCardDiscount;
    const deliveryPrice = getters.getDeliveryPrice;
    const insuranceAmount = getters.getInsuranceAmount;
    const orderSubtotalAmount = getters.getOrderSubtotalAmount;
    const couponDiscountAmount = getters.getCouponDiscountAmount;

    totalAmount = (orderSubtotalAmount + deliveryPrice + insuranceAmount) - couponDiscountAmount - giftCardDiscount;
    totalAmount = totalAmount < 0 ? 0 : totalAmount

    let orderData = _.cloneDeep(state.orderData);
    orderData[state.activeOrderDataIndex]['total_amount'] = totalAmount;
    state.orderData = orderData;

    return totalAmount;
};
