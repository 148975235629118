import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    couponTypes: {
        1: {
            id: 1,
            tag: 'amount',
            name: 'Flat amount of'
        },
        2: {
            id: 2,
            tag: 'percent',
            name: 'Percentage of'
        },
    },
    usaStates: [
        { name: "Alabama"},
        { name: "Alaska"},
        { name: "Arizona"},
        { name: "Arkansas"},
        { name: "California"},
        { name: "Colorado"},
        { name: "Connecticut"},
        { name: "Delaware"},
        { name: "Florida"},
        { name: "Georgia"},
        { name: "Hawaii"},
        { name: "Idaho"},
        { name: "Illinois"},
        { name: "Indiana"},
        { name: "Iowa"},
        { name: "Kansas"},
        { name: "Kentucky"},
        { name: "Louisiana"},
        { name: "Maine"},
        { name: "Maryland"},
        { name: "Massachusetts"},
        { name: "Michigan"},
        { name: "Minnesota"},
        { name: "Mississippi"},
        { name: "Missouri"},
        { name: "Montana"},
        { name: "Nebraska"},
        { name: "Nevada"},
        { name: "New Hampshire"},
        { name: "New Jersey"},
        { name: "New Mexico"},
        { name: "New York"},
        { name: "North Carolina"},
        { name: "North Dakota"},
        { name: "Ohio"},
        { name: "Oklahoma"},
        { name: "Oregon"},
        { name: "Pennsylvania"},
        { name: "Rhode Island"},
        { name: "South Carolina"},
        { name: "South Dakota"},
        { name: "Tennessee"},
        { name: "Texas"},
        { name: "Utah"},
        { name: "Vermont"},
        { name: "Virginia"},
        { name: "Washington"},
        { name: "West Virginia"},
        { name: "Wisconsin"},
        { name: "Wyoming"},
    ],
    insurance_amount: 2,
    countryPackages: [],
    activeOrderDataIndex: -1,
    orderData: [],
    orderDataTemplate:
        {
            continent_id: null,
            country_id: null,
            country_name: '',
            country_api_id: null,

            start_date: null,
            number_of_days: 1,
            number_of_devices: 1,

            plan_type: 'day',
            plan_id: null,
            plan_name: '',

            shipment_type: 'pickup',
            shipping_type_id: null,

            pickup_location_id: null,
            pickup_location_api_id: null,
            pickup_location_name: '',
            pickup_location_note: '',
            pickup_date: null,

            delivery_type_id: null,
            delivery_type_api_id: null,
            delivery_type_charges: 0,
            delivery_type_name: '',
            delivery_type_description: '',

            coupon_id: null,
            coupon_code: '',
            coupon_type: null,
            coupon_discount_amount: 0,

            gift_card_id: null,
            gift_card_code: '',
            gift_discount_amount: null,

            insurance_status: false,

            return_date: null,

            user_id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',

            address: null,
            city: null,
            country: null,
            state: null,
            zip_code: null,

            total_amount: 0
        }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};