<template>
    <app-layout>
        <div class="w-full bg-white">
            <div class="py-32 px-4 flex justify-center gap-8 xl:gap-0 xl:justify-between items-center container mx-auto max-w-5xl flex-wrap">
                <div>
                    <h1 class="text-5xl font-semibold mb-10 text-center text-sky-300">
                        Thank You!
                    </h1>

                    <div class="mb-10 text-center">
                        <h1 class="text-2xl font-semibold text-orange-500">
                            Your purchase was successful
                        </h1>
                        <p class="font-semibold  text-orange-500">Orders: </p>
                        <template v-for="order in orderData">
                            <p class="font-semibold text-orange-500">#{{ order.order_id }}</p>
                            <p class="font-semibold text-orange-500">Email: {{ order.email }}</p>
                            <p class="font-semibold text-orange-500">First name: {{ order.first_name }}</p>
                            <p class="font-semibold text-orange-500">Last name: {{ order.last_name }}</p>
                            <p class="font-semibold text-orange-500">Phone number: {{ order.phone_number }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">City: {{ order.city }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">Zip: {{ order.zip_code }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">State: {{ order.state }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">Country: {{ order.country_name }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">Shipping: {{ order.shipping_type_name }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.is_delivery">Shipping type: {{ order.shipping_type_description }}</p>

                            <p class="font-semibold text-orange-500" v-if="order.return_by_mail">Return City: {{ order?.return_city }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.return_by_mail">Return Zip: {{ order?.return_zip_code }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.return_by_mail">Return State: {{ order?.return_state }}</p>
                            <p class="font-semibold text-orange-500" v-if="order.return_by_mail">Return Country: {{ order?.return_address }}</p>
                            <p class="font-semibold text-orange-500">Coupon: {{ order.coupon_code }}</p>
                            <p class="font-semibold text-orange-500">Total amount: ${{ order.total_amount }}</p>
                        </template>
                    </div>
                    <button
                        class="mb-10 btn pointer capitalize text-center text-xl font-semibold flex items-center gap-4 main-bg m-auto text-white border-none py-4 px-10 rounded-3xl"
                        @click="redirectAndClear"
                    >
                        Got to home page
                    </button>
                </div>
                <purchase-checkout-product-info />
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PurchaseCheckoutProductInfo from "./PurchaseCheckoutProductInfo";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "PurchaseCheckoutResult",
    components: {PurchaseCheckoutProductInfo, AppLayout},
    computed: {
        ...mapGetters({
            user: 'getUser',
            paymentData: 'getPaymentData',
            orderData: 'purchase/getOrderData',
            allOrderData: 'getAllOrderData',
            countryPackages: 'getCountryPackages',
        }),
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        this.allOrderData.forEach(order => {
            window.dataLayer.push({
                'event': 'purchase',
                'product': {
                    'order_id': order.order_id,
                    'total_amount': order.total_amount,
                    'plan_amount': order.plan_amount,
                    'coupon_code': order.coupon_code,
                    'coupon_amount': order.coupon_amount,
                    'number_of_devices': order.number_of_devices,
                    'number_of_days': order.number_of_days,
                    'gift_card_id': order.gift_card_id,
                },
                'shipping': {
                    'state': order.state,
                    'country': order.country,
                    'zip': order.zip_code,
                    'city': order.city,
                    'shipping_price': order.shipping_type_charges,
                },
                'user': {
                    'email': order.email,
                    'phone': order.phone_number,
                    'first_name': order.first_name,
                    'last_name': order.last_name,
                }
            });
        })
    },
    methods: {
        redirectAndClear() {
            this.$store.dispatch('purchase/clearOrderData');
            this.$store.dispatch('purchase/setActiveOrderDataIndex', -1);
            this.$router.push({ name: 'Home' });
        },
        handlePageUnload() {
            this.redirectAndClear();
        },
    },
    created() {
        window.addEventListener('beforeunload', this.handlePageUnload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handlePageUnload);
    }

}
</script>

<style scoped>

</style>