<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t('contact.customer_support.title') }}</h1>
        <div class="container mx-auto flex items-start justify-between flex-wrap md:flex-nowrap px-2 sm:px-0">
            <div class="w-full">
                <h2 class="text-4xl font-bold mb-12">{{ $t('contact.customer_support.contact_us') }}</h2>
                <p class="font-semibold mb-12 max-w-2xl">{{ $t('contact.customer_support.body') }}</p>
                <div class="flex items-start gap-6 flex-wrap mb-16">
                    <div class="flex items-start gap-2">
                        <img src="@/assets/svg/location-icon.svg" alt="">
                        <div>
                            <h3 class="text-xl font-bold">Address</h3>
                            <p>370 Crenshaw Blvd E207, Torrance, CA 90503</p>
                        </div>
                    </div>
                    <div class="flex items-start gap-2">
                        <img src="@/assets/svg/mail-icon.svg" alt="">
                        <div>
                            <h3 class="text-xl font-bold">Email</h3>
                            <p>info@skygowifi.com</p>
                        </div>
                    </div>
                </div>
                <h3 class="text-center text-xl text-orange-600">{{ $t('contact.customer_support.logo_text') }}</h3>
                <img class="mx-auto" src="@/assets/png/skygo-logo.png" alt="">
            </div>
            <div class="w-full">
                <h3 class="text-3xl font-bold mb-12 mt-12 sm:mt-0">Get in touch</h3>
                <input-text
                    placeholder="Please type in your name"
                    v-model="form.name"
                >
                </input-text>
                <input-text
                    placeholder="Please type in your email"
                    v-model="form.email"
                >
                </input-text>
                <textarea v-model="form.message" placeholder="Input your message" class="h-56 border-sky-300 outline-blue-300 block w-full rounded-xl border focus:outline-offset-3 focus:outline-4 pl-4 text-base p-2" />
                <button
                    @click="sendMessage"
                    class="px-12 mt-6 rounded-xl hover:bg-sky-300 hover:text-white transition border text-base p-2 bg-white border-sky-300 text-black outline-blue-300"
                >
                    {{ loading ? 'Submit' : 'Send' }}
                </button>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import InputText from "@/components/ui/inputs/InputText";
import {useI18n} from "vue-i18n/dist/vue-i18n";
export default {
    name: "CustomerSupport",
    components: {InputText, AppLayout},
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    },
    data() {
        return {
            form: {
                name: '',
                message: '',
                email: ''
            },
            loading: false
        }
    },
    methods: {
        async sendMessage() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (this.validateForm()) {
                if (!emailRegex.test(this.form.email)) {
                    this.$toast.warning('Please enter a valid email address', { position: 'bottom-left' });
                    return;
                }

                this.loading = true;
                try {
                    const response = await this.submitForm();
                    this.clearForm();
                    this.loading = false;
                    // this.$router.push({ name: 'ContactResultPage' });
                } catch (error) {
                    if (error.status === 422) {
                        this.errors = error.data.errors;
                    }
                    this.loading = false;
                }
            } else {
                this.$toast.warning('Please fill all fields', { position: 'bottom-left' });
            }
        },
        validateForm() {
            return this.form.name && this.form.message && this.form.email;
        },
        async submitForm() {
            return this.$axios.post(`/contacts/contact-us`, this.form);
        },
        clearForm() {
            this.form.name = '';
            this.form.message = '';
            this.form.email = '';
        },
    }
}
</script>

<style scoped>

</style>