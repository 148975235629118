<script>
import InputRadio from "@/components/ui/inputs/InputRadio.vue";
import {mapGetters} from "vuex";
import InputSelect from "@/components/ui/inputs/InputSelect.vue";
import SelectInput from "@/components/ui/inputs/SelectInput.vue";
import TextInput from "@/components/ui/inputs/TextInput.vue";
import InputText from "@/components/ui/inputs/InputText.vue";
import {setPickupDate} from "@/views/purchase/_store/actions";
import moment from "moment/moment";

export default {
    name: "PurchaseRentShipment",

    components: {InputText, TextInput, SelectInput, InputSelect, InputRadio},

    data() {
        return {
            handleShipmentType: 'pickup',
            pickupLocations: [],
            selectedPickupLocation: {},

            deliveryTypes: [],
            selectedDeliveryType: {},
            deliveryForm: {
                address: "",
                city: "",
                state: "",
                zip_code: "",
                country: "",
            },
            startDateMasks: {
                input: 'MM/DD/YYYY',
            },
            selectedState: {},
        }
    },

    computed: {
        ...mapGetters({
            usaStates: 'purchase/getUsaStates',
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
        activeOrder() {
            return this.orderData[this.activeOrderDataIndex];
        },
        getDiff() {
            let startDate = this.activeOrder.start_date;
            const start = startDate ? new Date(startDate) : new Date();
            const today = new Date();

            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            return Math.ceil((today - start) / (1000 * 60 * 60 * 24));
        },
        formattedDateFromToday() {
            let startDate = this.activeOrder.start_date;
            const referenceDate = new Date(startDate || new Date());

            if (this.getDiff >= 11) {
                referenceDate.setDate(referenceDate.getDate() - 2); // Минус 2 дня
            } else {
                referenceDate.setDate(referenceDate.getDate() + 2); // Плюс 2 дня
            }

            return referenceDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
        },
        disabledPickupDates() {
            let disabledDates = [];

            if (this.activeOrder.pickup_location_id) {
                switch (this.activeOrder.pickup_location_api_id) {
                    // Torrance Office
                    case 354:
                        disabledDates.push({
                            repeat: {
                                weekdays: [1, 7]
                            }
                        });
                        break;
                    case 364:
                        disabledDates.push({
                            repeat: {
                                weekdays: [3, 4]
                            }
                        });
                        break;
                    case 373:
                        disabledDates.push({
                            repeat: {
                                weekdays: [3]
                            }
                        });
                        break;
                    case 377:
                        disabledDates.push({
                            repeat: {
                                weekdays: [1, 3, 5]
                            }
                        })
                        break;
                }
            }

            return disabledDates;
        }
    },

    watch: {
        handleShipmentType(value) {
            this.$store.dispatch('purchase/setShipmentType', value)
        },
        activeOrder() {
            this.initSelectedPickupLocation();
        },
        deliveryForm: {
            handler(newValue, oldValue) {
                this.$store.dispatch('purchase/setDeliveryForm', newValue)
            },
            deep: true
        },
    },

    created() {
        this.loadPickupLocations();
        this.loadShipmentTypes();
        this.initData();
    },

    methods: {
        selectPickupDate(value) {
            this.$store.dispatch("purchase/setPickupDate", moment(value.date).format())
        },
        selectPickupLocation(value) {
            this.$store.dispatch("purchase/setPickupLocation", value)
        },
        selectDeliveryType(value) {
            this.$store.dispatch("purchase/setDeliveryType", value)
        },
        handleState(state) {
            this.deliveryForm.state = state.name;
        },
        loadPickupLocations() {
            this.$axios.get(`/pickup/getLocations`)
                .then(res => {
                    this.pickupLocations = res.data;
                    this.initSelectedPickupLocation();
                });
        },
        loadShipmentTypes() {
            this.$axios.get(`/shipping/getTypes`)
                .then(res => {
                    this.deliveryTypes = res.data;
                    this.initSelectedDeliveryType();
                });
        },
        initSelectedPickupLocation() {
            let findLocation = this.pickupLocations.find(location => location.id === this.activeOrder.pickup_location_id);
            if (findLocation) {
                this.selectedPickupLocation = findLocation
            } else {
                this.selectedPickupLocation = {}
            }
        },
        initSelectedDeliveryType() {
            let findDeliveryType = this.deliveryTypes.find(type => type.id === this.activeOrder.delivery_type_id);
            if (findDeliveryType) {
                this.selectedDeliveryType = findDeliveryType
            } else {
                this.selectedDeliveryType = {}
            }
        },
        initSelectedUsaState() {
            let findState = this.usaStates.find(state => state.name === this.activeOrder.state);
            if (findState) {
                this.selectedState = findState
            } else {
                this.selectedState = {}
            }
        },
        initData() {
            this.deliveryForm.address = this.activeOrder.address;
            this.deliveryForm.state = this.activeOrder.state;
            this.deliveryForm.city = this.activeOrder.city;
            this.deliveryForm.country = this.activeOrder.country;
            this.deliveryForm.zip_code = this.activeOrder.zip_code;

            this.initSelectedUsaState();
        }
    }
}
</script>

<template>
    <div class="purchase-rent-shipment">
        <div class="purchase-rent-shipment__title">
            <h1>Select Pickup or delivery</h1>
        </div>

        <div class="purchase-rent-shipment__types">
            <input-radio
                name="delivery-or-pickup"
                :label="$t('order.pickup')"
                value="pickup"
                :checked="activeOrder.shipment_type === 'pickup'"
                v-model="handleShipmentType"
            />

            <input-radio
                name="delivery-or-pickup"
                :label="$t('order.deliver')"
                class="mr-4"
                value="delivery"
                :checked="activeOrder.shipment_type === 'delivery'"
                v-model="handleShipmentType"
            />
        </div>

        <div
            v-if="activeOrder.shipment_type === 'pickup'"
            class="purchase-rent-shipment__types-pickup-form"
        >
            <div class="purchase-rent-shipment__types-pickup-form-location">
                <h4>Select location</h4>

                <select-input
                    v-model="selectedPickupLocation"
                    placeholder="Location"
                    :options="pickupLocations"
                    @selected="selectPickupLocation"
                />
            </div>

            <div class="purchase-rent-shipment__types-pickup-form-date">
                <h4>Pickup date</h4>

                <v-date-picker
                    v-model="activeOrder.pickup_date"
                    :masks="startDateMasks"
                    :disabled-dates="disabledPickupDates"
                    @dayclick="selectPickupDate"
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="relative">
                            <input
                                class="purchase-rent-plan__body-date__start-date__input"
                                :value="inputValue"
                                v-on="inputEvents"
                                placeholder="Select start date"
                            />

                            <svg class="date-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FF8818"><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/></svg>
                        </div>
                    </template>
                </v-date-picker>
            </div>
        </div>

        <div
            v-if="activeOrder.shipment_type === 'delivery'"
            class="purchase-rent-shipment__types-delivery-form"
        >
            <div
                class="purchase-rent-shipment__types-delivery-form__receive-date-info"
            >
                You can expect to receive the device on {{ formattedDateFromToday  }}
            </div>

            <select-input
                v-model="selectedDeliveryType"
                label="Shipping type"
                placeholder="Shipping type"
                :options="deliveryTypes"
                @selected="selectDeliveryType"
            />

            <div
                v-if="activeOrder.delivery_type_id"
                class="purchase-rent-shipment__types-delivery-form__shipping-type-info"
            >
                <p
                    class="text-red-500"
                    v-html="activeOrder.delivery_type_description"
                ></p>
            </div>

            <text-input
                :label="$t('order.address')"
                v-model="deliveryForm.address"
                :placeholder="$t('order.address')"
            />

            <text-input
                :label="$t('order.city')"
                v-model="deliveryForm.city"
                :placeholder="$t('order.city')"
            />

            <text-input
                :label="$t('order.country')"
                :placeholder="$t('order.country')"
                v-model="deliveryForm.country"
            />

            <div class="flex gap-2 flex-wrap sm:flex-nowrap">
                <select-input
                    v-model="selectedState"
                    :label="$t('order.state')"
                    :placeholder="$t('order.state')"
                    :options="usaStates"
                    @selected="handleState"
                />

                <text-input
                    :label="$t('order.zip')"
                    v-model="deliveryForm.zip_code"
                    :placeholder="$t('order.zip')"
                />
            </div>
        </div>
    </div>

</template>

<style lang="scss">
.purchase-rent-shipment {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &__title {
        margin-bottom: 2rem;

        h1 {
            text-align: left;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: .5rem;
        }
    }

    &__types {
        margin-bottom: 1.5rem;

        &-pickup-form {
            width: 50%;

            h4 {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: .5rem;
            }

            &__input {
                width: 100%;
                box-sizing: border-box;
                background: #ffffff;
                border: 2px solid #FE9634;
                border-radius: 8px;
                padding: .8rem 3rem;
                font-size: 1rem;
                outline: none;
            }

            .date-icon {
                position: absolute;
                top: .9rem;
                left: 1rem;
            }

            &-location {
                margin-bottom: 1.5rem;
            }
        }

        &-delivery-form {
            width: 50%;

            &__receive-date-info {
                background: #ffffff;
                border-radius: 8px;
                border: 2px solid #7DD3FC;
                padding: .5rem 1rem;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 1.5rem;
            }

            &__shipping-type-info {
                background: #ffffff;
                border-radius: 8px;
                border: 2px solid #7DD3FC;
                padding: .5rem 1rem;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 1.5rem;
            }

            .select-input {
                margin-bottom: 1rem;
            }

            .text-input {
                margin-bottom: 1rem;
            }
        }
    }
}

@media (max-width: 431px) {
    .purchase-rent-shipment {
        &__types {
            &-pickup-form {
                width: 100%;
            }

            &-delivery-form {
                width: 100%;
            }
        }
    }
}
</style>