<template>
    <div class="flex items-center mb-4">
        <label class="input-radio">
            <input
                :name="name"
                :disabled="disable"
                type="radio"
                :value="value"
                :checked="checked"
                @change="$emit('update:modelValue', $event.target.value)"
            />
            <span>{{ label }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: "InputRadio",
    props: {
        size: {
            type: String,
            default() {
                return 'base'
            }
        },
        disable: {
            type: Boolean,
            default() {
                return false
            }
        },
        checked: {
            type: Boolean,
            default() {
                return false
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        name: {
            type: String,
            default() {
                return ''
            }
        },
        modelValue: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
    },
    methods: {
        inputStyles() {
            let styles = `border-none`;

            if (this.size === 'base') {
                styles += ` w-4 h-4`
            }

            if (this.size === 'sm') {
                styles += ` w-6 h-6`
            }

            if (this.size === 'lg') {
                styles += ` w-8 h-8`
            }

            return styles
        },

        labelStyles() {
            let labelStyle = `ml-2 block`
            if (this.disable) {
                labelStyle += ` text-gray-500`
            }
            if (this.size === 'base') {
                labelStyle += ` text-base`;
            }
            if (this.size === 'sm') {
                labelStyle += ` text-sm`;
            }
            if (this.size === 'lg') {
                labelStyle += ` text-lg`
            }

            return labelStyle
        },

        radioChecked() {
            let checked = false
            if (this.value === this.modelValue) {
                checked = true
            }
            return checked
        }
    },
}
</script>

<style lang="scss">
    :root {
        --input-radio-color: #7DD3FC;
    }

    .input-radio {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;

        span {
            margin-bottom: 4px;
        }
    }

    .input-radio + .input-radio {
        margin-top: 1em;
    }

    .input-radio:focus-within {
        color: var(--input-radio-color);
    }

    input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0 1rem 0 0;

        font: inherit;
        color: #9ca3af;
        width: 25px;
        height: 25px;
        border: 2px solid #7DD3FC;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
    }

    input[type="radio"]::before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--input-radio-color);
    }

    input[type="radio"]:checked {
        color: var(--input-radio-color);
        border: 0.15em solid var(--input-radio-color);
    }

    input[type="radio"]:checked::before {
        transform: scale(1);
    }

    input[type="radio"]:focus {
        color: var(--input-radio-color);
        border: 0.15em solid var(--input-radio-color);
    }
</style>