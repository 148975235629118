<script>
export default {
    name: "TextInput",
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            default() {
                return '';
            },
        },
    },
    data() {
        return {
            isFocused: false
        }
    },
    methods: {
        handleFocus: _.debounce(function (value) {
            this.isFocused = value;
        }, 500),

        handleInput: _.debounce(function (value) {
            this.$emit('update:modelValue', value)
        }, 500),
    }
}
</script>

<template>
    <div class="text-input">
        <label
            v-if="label"
            class="text-input__label"
        >
            {{ label }}
        </label>

        <div class="text-input__form">
            <input
                type="text"
                v-model="modelValue"
                :placeholder="placeholder"
                @input="handleInput($event.target.value)"
                @focus="handleFocus(true)"
                @blur="handleFocus(false)"
            />
        </div>
    </div>
</template>

<style lang="scss">
.text-input {
    position: relative;

    &__label {
        font-size: 14px;
        color: #000000;
        margin-bottom: .5rem;
        display: inline-block;
    }

    &__form {
        display: flex;
        height: 50px;
        width: 100%;
        border: 2px solid #FF8818;
        border-radius: .5rem;
        background: #ffffff;
        padding: 0 1rem;

        input {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            font-size: 1rem;

            &:focus {
                border: none;
                outline: none;
            }
        }
    }
}
</style>