<script>

import {mapGetters} from "vuex";

export default {
    name: "PurchaseRentCountry",

    data() {
        return {
            loading: false,
            countries: []
        }
    },

    computed: {
        ...mapGetters({
            orderData: 'purchase/getOrderData',
            activeOrderDataIndex: 'purchase/getActiveOrderDataIndex',
        }),
    },

    created() {
        this.loadPopularCountries();
    },

    methods: {
        async loadPopularCountries() {
            this.loading = true;
            const response = await this.$axios.get(`/countries/popular`, {
                params: {
                    limit: 10
                }
            });

            this.countries = response.data;

            this.loading = false;
        },

        selectCountry(country) {
            this.$emit('selectCountry', country);
        }
    }
}

</script>

<template>

    <div class="purchase-rent-country">
        <div class="purchase-rent-country__title">
            <h1>Choose from Popular Destinations</h1>
        </div>

        <div class="purchase-rent-country__items">
            <div
                v-for="(country, i) in countries"
                class="purchase-rent-country__item"
                :class="{'active': country.id === orderData[activeOrderDataIndex].country_id}"
                @click="selectCountry(country)"
            >
                <img
                    v-if="i < 3"
                    class="purchase-rent-country__item-top-img"
                    :src="require(`../../../assets/png/top-${i+1}.png`)"
                >
                <img
                    class="purchase-rent-country__item-flag-img"
                    :src="require(`../../../assets/png/flags/${country.img}`)"
                    :alt="country.name"
                >

                <div class="purchase-rent-country__item-info">
                    <p class="purchase-rent-country__item-info-name">
                        {{ country.name }}
                    </p>
                    <div class="flex justify-between">
                        <p class="purchase-rent-country__item-info-plan">
                            {{ country.plan_name }}
                        </p>
                        <p class="purchase-rent-country__item-info-price">
                            From ${{ country.plan_price }}/Day
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

.purchase-rent-country {
    position: relative;
    margin-top: 2rem;

    &__title {
        margin-bottom: 2rem;

        h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: .5rem;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        position: relative;
        background: #fff;
        width: 259px;
        height: 160px;
        border-radius: 8px;
        border: 2px solid #7DD3FC;
        cursor: pointer;
        margin: .5rem;
        padding: 0.5rem 1rem;

        &-flag-img {
            width: 75px;
            margin-bottom: .5rem;
        }

        &-info-name {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: .5rem;
        }

        &-info-plan {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }

        &-info-price {
            font-size: 16px;
            font-weight: 400;
        }

        &-top-img {
            position: absolute;
            right: 1rem;
            top: -0.5rem;
            width: 50px;
            height: 50px;
        }

        &:hover, &.active {
            background: #7DD3FC;
            color: #fff;
        }
    }
}

@media (max-width: 567px) {
    .purchase-rent-country {
        &__item {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;

            &-flag-img {
                margin-right: 1rem;
                margin-bottom: 0;
            }

            &-info {
                width: 100%;
            }
        }
    }
}
</style>