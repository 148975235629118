<script>
export default {
    name: "SelectInput",
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: []
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            default() {
                return null;
            },
        },
        selectPropertyName: {
            type: String,
            default() {
                return 'value';
            },
        },
        showPropertyName: {
            type: String,
            default() {
                return 'name';
            },
        }
    },
    data() {
        return {
            isFocused: false
        }
    },
    methods: {
        handleFocus: _.debounce(function (value) {
            this.isFocused = value;
        }, 100),

        handleInput: _.debounce(function (value) {
            //
        }, 500),

        selectItem(option) {
            this.$emit('update:modelValue', option);
            this.$emit('selected', option);
        }
    }
}
</script>

<template>
    <div class="select-input">
        <label
            v-if="label"
            class="select-input__label"
        >
            {{ label }}
        </label>

        <div class="relative">
            <div class="select-input__form">
                <input
                    type="text"
                    v-model="modelValue[showPropertyName]"
                    :placeholder="placeholder"
                    @input="handleInput($event.target.value)"
                    @focus="handleFocus(true)"
                    @blur="handleFocus(false)"
                />
            </div>

            <div
                v-if="isFocused"
                class="select-input__menu"
            >
                <div
                    v-for="option in options"
                    class="select-input__menu-item"
                    @click="selectItem(option)"
                >
                    {{ option[showPropertyName] }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .select-input {
        position: relative;

        &__label {
            font-size: 14px;
            color: #000000;
            margin-bottom: .5rem;
            display: inline-block;
        }

        &__form {
            display: flex;
            height: 50px;
            width: 100%;
            border: 2px solid #FF8818;
            border-radius: .5rem;
            background: #ffffff;
            padding: 0 1rem;

            input {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                font-size: 1rem;

                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }

        &__menu {
            width: 100%;
            min-height: 56px;
            background: #ffffff;
            border: 2px solid #FF8818;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            border-top: none;
            position: absolute;
            z-index: 100;
            top: 42px;
            padding: .5rem 0;

            &-item {
                cursor: pointer;
                padding: .5rem 1rem;

                &:hover {
                    background: #7DD3FC;
                    color: #fff;
                }
            }
        }
    }
</style>