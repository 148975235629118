export const selectCountry = ({ commit }, payload) => {
    console.log('selectCountry')
    commit('setSelectedCountry', payload);
};

export const setCountryPackages = ({ commit }, payload) => {
    console.log('setCountryPackages')
    commit('setCountryPackages', payload);
};

export const setStartDate = ({ commit }, payload) => {
    console.log('setStartDate')
    commit('setStartDate', payload);
};

export const setNumberOfDays = ({ commit }, payload) => {
    console.log('setNumberOfDays')
    commit('setNumberOfDays', payload);
};

export const setPlanType = ({ commit }, payload) => {
    console.log('setPlanType')
    commit('setPlanType', payload);
};

export const setNumberOfDevices = ({ commit }, payload) => {
    console.log('setNumberOfDevices')
    commit('setNumberOfDevices', payload);
};

export const selectPlan = ({ commit }, payload) => {
    console.log('selectPlan')
    commit('setSelectedPlan', payload);
};

export const setShipmentType = ({ commit }, payload) => {
    console.log('setShipmentType')
    commit('setShipmentType', payload);
};

export const setDeliveryForm = ({ commit }, payload) => {
    console.log('setDeliveryForm')
    commit('setDeliveryForm', payload);
};

export const setPickupLocation = ({ commit }, payload) => {
    console.log('setPickupLocation')
    commit('setPickupLocation', payload);
};

export const setPickupDate = ({ commit }, payload) => {
    console.log('setPickupDate')
    commit('setPickupDate', payload);
};

export const setDeliveryType = ({ commit }, payload) => {
    console.log('setDeliveryType')
    commit('setDeliveryType', payload);
};

export const setCoupon = ({ commit }, payload) => {
    console.log('setCoupon')
    commit('setCoupon', payload);
};

export const setGiftCard = ({ commit }, payload) => {
    console.log('setGiftCard')
    commit('setGiftCard', payload);
};

export const setInsuranceStatus = ({ commit }, payload) => {
    console.log('setInsuranceStatus')
    commit('setInsuranceStatus', payload);
};

export const setReturnDate = ({ commit }, payload) => {
    console.log('setReturnDate')
    commit('setReturnDate', payload);
};

export const setOrderData = ({ commit, getters }, payload) => {
    console.log('setOrderData')
    commit('setOrderData', payload);
};

export const clearOrderData = ({ commit, getters }, payload) => {
    console.log('clearOrderData')
    commit('clearOrderData', payload);
};

export const setActiveOrderDataIndex = ({commit, getters}, payload) => {
    console.log('setActiveOrderDataIndex')
    commit('setActiveOrderDataIndex', payload);
};

export const removeOrderDataByIndex = ({state, commit}, payload) => {
    console.log('removeOrderDataByIndex')
    let orderData = _.cloneDeep(state.orderData);
    orderData.splice(payload, 1);
    commit('setOrderData', orderData);
    commit('setActiveOrderDataIndex', state.activeOrderDataIndex - 1);
};

export const addAnotherOrderData = ({ commit, state }) => {
    console.log('addAnotherOrderData')
    let newOrderData = _.cloneDeep(state.orderDataTemplate);
    let orderData = _.cloneDeep(state.orderData);

    orderData.push(newOrderData);

    commit('setCountryPackages', []);
    commit('setOrderData', orderData);
    commit('setActiveOrderDataIndex', state.activeOrderDataIndex + 1);
};
